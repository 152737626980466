import { getPluginLocation } from "./pluginUtil";

export const getAscPageType = (): ASCPageType => {
  let plugin_location = getPluginLocation();

  const pluginLocationConfig = PLUGIN_LOCATION_CONFIGS[plugin_location];
  if (pluginLocationConfig?.location_type && pluginLocationConfig?.location_type != "auto") {
    plugin_location = pluginLocationConfig?.location_type;
  }

  if (window.asc_datalayer?.page_type) {
    return window.asc_datalayer?.page_type;
  }
  const cleanLocation = plugin_location?.replace("_mobile", "") ?? "";
  switch (cleanLocation) {
    case "srp":
      return "itemlist";
    case "vdp":
      return "item";
    case "home":
      return "home";
    case "landing":
    case "sell-us-your-car":
      return "trade";
    case "track-vehicle-value":
      return "trade";
  }
  return "unknown";
};
